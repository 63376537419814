/* Default styles for desktop (vertical layout) */
.App {
  display: flex;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  flex-direction: row; /* Default to row for desktop layout */
}

.logo-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row; /* Default to row for desktop layout */
}

.logo-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.logo-box img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.logo-box.white {
  background-color: white;
}

.logo-box.black {
  background-color: black;
}

.logo-box.custom {
  background-color: #023644; /* Custom color */
}

.logo-box.custom img {
  max-width: 60%; /* 75% of 80% to make it 25% smaller */
  max-height: 60%; /* 75% of 80% to make it 25% smaller */
}

@media (max-width: 768px) {
  .App {
    flex-direction: column; /* Change to column for vertical layout on mobile */
  }

  .logo-container {
    flex-direction: column; /* Change to column for vertical layout on mobile */
  }

  .logo-box {
    height: 33.33%; /* Adjust height to fit within the screen vertically */
  }

  .logo-box img {
    max-width: 80%;
    max-height: 80%;
  }

  .logo-box.custom img {
    max-width: 60%;
    max-height: 60%;
  }
}